<template>
  <div class="c-app flex-row align-items-center mainbg">
    <div id="signup">
  <header>
    <img src="img/logo/logo.png" style="height: 70px" />
                      <img
                        src="img/logo/fraudavoid_ logo.png"
                        style="height: 70px; width: 180px"
                      />
  </header>
  <div id="intro">
    <h1>Grow your business with FraudAvoid</h1>
    <p>Get started with a free account and see what we can do for you.</p>
  </div>
    <form @submit.prevent="signup">
      <div class="form-group text-left">
        <label for="name">Full name</label>
        <CInput type="text" id="name" v-model="input.username" :state="validateName" maxlength="100" placeholder="e.g John Doe" required />
      </div>
      <div class="form-group text-left">
        <label for="email">Work Email</label>
        <CInput type="email" id="email" maxlength="100" v-model="input.email" placeholder="e.g info@example.com" autocomplete="email" required />
      </div>
      <div class="form-group text-left">
        <label for="password">Enter new password <small>(min. 8 characters)</small></label>
        <CInput type="password" id="password" v-model="input.password" maxlength="100" placeholder="********" autocomplete="new-password" required />
      </div>
      <div class="form-group text-left">
        <label for="password">Re-enter Password</label>
        <CInput type="password" id="retype_password" v-model="retype_password" maxlength="100" placeholder="********" autocomplete="new-password" required />
        <small><span class="text-danger">*</span> Password must contain at least 8 characters, one special character and alphanumeric characters.</small>
      </div>
      <div class="text-left">
        <ul>
          <li>You will get 30 days free trial.</li>
          <li>No credit card required for registration.</li>
          <li>By signing up, you agree to our <small><a href="https://scienlabs.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Terms Of Service and Privacy Policy</a></small>.</li>
        </ul>
      </div>
      <button type="submit">Create Account</button>
    </form>

  <div id="agreements">
    <label for="terms">
      <span class="link" @click="gotoPage('login')"
                    >Already have an account?</span
                  >
    </label>
  </div>
</div>
  </div>
</template>

<script>
import SignUpService from "./../../services/signupControlService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import HelperFunction from "./../../helper/helperFunction";
export default {
  name: "Register",
  data() {
    return {
      input: {
        email:'',
        username: "",
        password: "",
        status: 1
      },
      retype_password: "",
    };
  },
  computed: {
    validateName(){
      if (this.input.username == "") {
        return false;
      }else if(HelperFunction.validateName(this.input.username,100) == false) {
        this.input.username = ''
        return false;
      }else{
        return true;
      }
    }
  },
  mounted() {},
  methods: {
    gotoPage(txt) {
      switch (txt) {
        case "login":
          this.$router.push("/pages/login");
          break;
      }
    },
    async signup() {
      try {
        if (this.validate()) {
          let response;
          response = await SignUpService.signup(this.input);
          this.$root.$data.user_email = this.input.email
          if (response.result == true) {
            Swal.fire({
              title: "Success!",
              text: "Successfully accepted your request, our team will get back to you very soon, or contact us at support@scienlabs.com",
              icon: "success",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 3000
            });
            this.$router.push("/pages/qna");
            // this.$router.push("/pages/login");
          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "error",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 3000
            });
            this.input.username = "";
            this.input.password = "";
            this.retype_password = "";
          }
        }
      } catch (e) {
        // this.errorNotify("Error", "connection error", "danger");
        console.log(e);
      }
    },
    passwordStrValid(password) {
        //check password regex
        const passwordRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/;
        return passwordRegex.test(password);
    },
    showError(message) {
      Swal.fire({title: "Error!",text: message,icon: "error",confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 3000});
    },
    validate() {
      var isValidEmail = HelperFunction.validateemail(this.input.email);
      var isValidPassword = this.passwordStrValid(this.input.password);
        if (!isValidEmail) {
          this.showError("Please enter a valid email");
          return false;
        }
        if (!isValidPassword) {
          this.showError("Password must contain at least 8 characters, one special character, and alphanumeric characters");
          return false;
        }
        if (this.input.password.length < 8 || this.input.password !== this.retype_password || this.input.username === "") {
          this.showError("Please enter all details");
          return false;
        }
        return true;
    },
  },
};
</script>



<style scoped>

#signup {
  width: 500px;
  margin: 0 auto;
  text-align: center;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header {
  background-color: #ffffff;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

header img {
  height: 50px;
}

#intro {
  padding-top: 20px;
}

#intro h1 {
  margin: 0;
  font-size: 24px;
  color: #00a4b4;
}

#intro p {
  margin: 20px 0;
  font-size: 16px;
  color: #202121;
}

form {
  padding: 20px;
  border-bottom: 1px solid #ecf0f1;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #2f3333;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ecf0f1;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #321fdb;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #1b0c9c;
}

#agreements {
  padding: 20px;
}

#agreements label {
  font-size: 14px;
  color: #1f2020;
}

#agreements a {
  color: #00a4b4;
  text-decoration: none;
}

#agreements a:hover {
  text-decoration: underline;
}
</style>